import React, { useState, useEffect, useContext } from "react";
import Calendar from "react-calendar"; // React Calendar component
import "react-calendar/dist/Calendar.css"; // Default styles for the calendar
import { format } from "date-fns";
import "./css/workload.css";
import { CalendarToday, AssignmentLate, Refresh } from "@mui/icons-material";


// Import the data fetching function and interfaces
import { apiGetWorkload, WorkLoadAnalysis } from "util/network/Workload"; // Update path as needed
import { FetchError } from "util/exceptions"; // Update path as needed
import AppStateContext from "contexts/AppStateContext";
import Grid from "@mui/material/Grid";

const WorkloadAnalysisComponent: React.FC = () => {
    const { token } = useContext(AppStateContext);
    const [workload, setWorkload] = useState<WorkLoadAnalysis | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    // Function to fetch workload data
    const fetchWorkloadData = async () => {
        setLoading(true);
        try {
            const data = await apiGetWorkload(token);
            setWorkload(data);
            setError(null);
        } catch (err) {
            if (err instanceof FetchError) {
                setError(`Error: ${err.message} (Status: ${err.status})`);
            } else {
                setError("An unexpected error occurred.");
            }
        } finally {
            setLoading(false);
        }
    };

    // Fetch data on component mount
    useEffect(() => {
        fetchWorkloadData();
    }, [token]);

    const getColor = (workloadValue: number): string => {
        if (workloadValue > 16) {
            return "rgba(128, 0, 128, 1)"; // Purple for high workloads
        }

        const normalizedValue = workloadValue / 16; // Normalize to [0, 1]
        const redIntensity = Math.round(255 - normalizedValue * (255 - 28));
        const greenIntensity = Math.round(255 - normalizedValue * (255 - 140));

        return `rgba(${redIntensity}, ${greenIntensity}, 255, 1)`; // Gradient from white to soft blue
    };

    // Render workload data
    const renderWorkloadData = () => {
        if (!workload) return null;

        return (
            <Grid container>
                <Grid item xs={3}
                    style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    // padding: "1rem",
                    }}
                >
                    <h2>Workload</h2>

                    <Grid item 
                        style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        alignItems: "center",
                        // padding: "1rem",
                        }}
                    >
                        
                        <span style={{ display: "flex", alignItems: "center", gap: "0.3rem" }}>
                            <CalendarToday 
                                titleAccess={`Tasks with No Deadline:\n${workload.tasks_ids_without_deadline.length > 0 
                                    ? workload.tasks_ids_without_deadline.sort().map((taskId) => `#${taskId}`).join("\n")
                                    : "None"}`} 
                            />
                            {workload.tasks_ids_without_deadline.length}
                        </span>
                        <span style={{ display: "flex", alignItems: "center", gap: "0.3rem" }}>
                            <AssignmentLate 
                                titleAccess={`Tasks with No Work-Time:\n${workload.tasks_ids_without_work.length > 0 
                                    ? workload.tasks_ids_without_work.sort().map((taskId) => `#${taskId}`).join("\n")
                                    : "None"}`} 
                            />
                            {workload.tasks_ids_without_work.length}
                        </span>
                        <button
                            onClick={fetchWorkloadData}
                            className="refresh-button"
                            title="Refresh Workload Data"
                        >
                            <Refresh />
                        </button>
                    </Grid>
                </Grid>
                <Grid item xs={9}
                    style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    paddingTop: "1rem",
                    }}
                >
                    <Calendar
                        className="custom-calendar"
                        tileContent={({ date }) => {
                            const formattedDate = format(date, "yyyy-MM-dd");
                            const dayData = workload.dates[formattedDate];
                            const workloadValue = dayData ? dayData.load : 0;
                            const taskLoads = dayData?.tasks_loads || {};

                            const taskListText = Object.keys(taskLoads).length > 0
                                ? Object.entries(taskLoads)
                                    .filter(([, load]) => load > 0) // Filter out tasks with 0.0 load
                                    .sort(([taskIdA], [taskIdB]) => Number(taskIdA) - Number(taskIdB)) // Sort by task ID
                                    .map(([taskId, load]) => `#${taskId}: ${load.toFixed(1)}h`)
                                    .join("\n")
                                : "No tasks";

                            const gradientStyle = {
                                background: getColor(workloadValue),
                                color: workloadValue > 8 ? "white" : "black",
                                // padding: "2px",
                            };

                            return (
                                <div 
                                    className="tile-content-wrapper"
                                    style={gradientStyle}
                                    title={taskListText}
                                >
                                    <p>{dayData ? workloadValue.toFixed(1)+"h" : ""}</p>
                                </div>
                            );
                        }}
                    />
                </Grid>
            </Grid>

        );
    };

    // Render loading, error, or workload data
    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return <div>{renderWorkloadData()}</div>;
};

export default WorkloadAnalysisComponent;
