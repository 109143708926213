import React, { useContext, useState } from "react";
import Modal from "./Modal";
import { apiGetSimiliarModelProductions} from "util/network/Productions";
import { Tooltip } from "@mui/material";
import { JoinRight } from "@mui/icons-material";
import PreviewButton from "components/Generic/PreviewButton";
import { ProductionVMType } from "types/ProductionType";
import AppStateContext from "contexts/AppStateContext";

interface Props {
  product: ProductVMType;
  production?: ProductionVMType;
}

const SimilarModelsModal: React.FC<Props> = ({ product, production }) => {
    const { token } = useContext(AppStateContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [similarModelProductions, setSimilarModelProductions] = useState<ProductionVMType[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Function to fetch data and show modal
  const handleButtonClickSimiliar = async () => {
    setLoading(true);
    setError(null);
    setIsModalOpen(true);
    try {
        const similiarProductions = await apiGetSimiliarModelProductions(token, product.details.modelId);
        setSimilarModelProductions(similiarProductions);
    } catch (err) {
        setError("Failed to fetch similar models");
        console.error(err);
    } finally {
        setLoading(false);
    }
  };

  return (
    <div>
      {/* Button to open modal */}
        <Tooltip title={"Find similiar productions"} >
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
            {product.details.modelId ?   
                (
                    <JoinRight onClick={handleButtonClickSimiliar} style={{ cursor: "pointer", verticalAlign: "middle" }} />
                ) : (
                    <JoinRight style={{ fill: "#EBEBEB", verticalAlign: "middle" }} />
                )
            }
            </div>
        </Tooltip>

      {/* Modal for displaying similar models */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <h2>Similar Models</h2>

            {error && <p style={{ color: "red" }}>{error}</p>}

            {!loading ? (
                <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "10px" }}>

                    {/* Reference Model */}
                    <thead>
                        <tr style={{ textAlign: "left", borderBottom: "2px solid #ddd", background: "#dbf4ef" }}>
                            <th >
                                <PreviewButton productId={product.id} modelId={product.details.modelId} size={"medium"} />
                            </th>
                            <th style={{paddingRight: "10px"}}>
                            <div>
                                <strong>#{product.id}</strong> {product.name}
                            </div>
                            <div style={{ fontSize: "0.9em", color: "#666"}}>
                                {Math.round(product.details.model.dimX)} x {Math.round(product.details.model.dimY)} x {Math.round(product.details.model.dimZ)}mm
                            </div>
                            {production && (
                                <div style={{ fontSize: "0.9em", color: "#666" }}>
                                    {new Date(production.created).toLocaleDateString()} <strong><a href={`/ordrestyring/${production.orderId}`}>#{production.orderId}</a></strong> {production.orderCompanyName}
                                </div>
                            )}
                            </th>
                        </tr>
                    </thead>

                    {/* Similiar models */}
                    <tbody>
                    {similarModelProductions.filter(similarProduction => product.id !== similarProduction.productId).length > 0 ? (
                        similarModelProductions
                            .filter(similarProduction => product.id !== similarProduction.productId)
                            .map((similiarProduction) => (
                                <tr key={similiarProduction.product.id} style={{ borderBottom: "2px solid #ddd"}}>
                                    <td >
                                        <PreviewButton productId={similiarProduction.product.id} modelId={similiarProduction.product.details.modelId} size={"medium"} />
                                    </td>
                                    <td style={{paddingRight: "10px"}}>
                                        <div>
                                            <strong>#{similiarProduction.productId}</strong> {similiarProduction.product.name}
                                        </div>
                                        <div style={{ fontSize: "0.9em", color: "#666" }}>
                                            {Math.round(similiarProduction.product.details.model.dimX)} x {Math.round(similiarProduction.product.details.model.dimY)} x {Math.round(similiarProduction.product.details.model.dimZ)}mm
                                        </div>
                                        <div style={{ fontSize: "0.9em", color: "#666" }}>
                                            {new Date(similiarProduction.created).toLocaleDateString()} <strong><a href={`/ordrestyring/${similiarProduction.orderId}`}>#{similiarProduction.orderId}</a></strong> {similiarProduction.orderCompanyName}
                                        </div>
                                    </td>
                                </tr>
                        ))
                    ) : (
                    <tr>
                        <p>No similar models found.</p>
                    </tr>
                        
                    )
                    }   
                    </tbody>
                </table>
            ) : (
                <p>Loading similiar models....</p>
            )}
        </Modal>
    </div>
  );
};

export default SimilarModelsModal;
