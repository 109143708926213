import {
  ProductionListType,
} from "types/ProductionType";
import { FetchError } from "util/exceptions";
import { API_URL } from "./common";

// Interface for WorkLoadAnalysisDate
export interface WorkLoadAnalysisDate {
  load: number;
  tasks_loads: Record<number, number>;
}


// Interface for WorkLoadAnalysis
export interface WorkLoadAnalysis {
  task_type: string;
  analysis_end_date: string; // ISO date string
  load: number;
  dates: { [key: string]: WorkLoadAnalysisDate }; // Key is date in ISO string format
  tasks_ids: number[];
  tasks_ids_without_deadline: number[];
  tasks_ids_without_work: number[];
}

/**
* Fetch workload data from the API.
* @returns WorkLoadAnalysis data.
*/
export const apiGetWorkload = (
  token: string,
): Promise<WorkLoadAnalysis> => {
  return fetch(`${API_URL}/v1/ecommerce/workload`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};
